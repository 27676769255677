import React, { Component, Fragment } from 'react'
import { Container, Spinner } from 'react-bootstrap'
import axios from 'axios'
import DataNews from './dataNews'
class Detail extends Component {
    state = {
        news: []
    }

    componentDidMount(){
        this.loadListBlog()
    }

    loadListBlog = () => {
        const url = process.env.REACT_APP_API + `article`;
        axios.get(url)
        .then(res => {
            const news = res.data;
            this.setState({news});
        })
    }
    render() {
        return (
            <Fragment>
                <section className="detail-news mt-0">
                    <h1 className='firstHeading'>Permata Puri Cibubur News</h1>
                    <Container>
                        <div className="section-body">
                            {
                                this.state.news.length > 0 ? this.state.news.map((blog, i) => {
                                    return(
                                        <div className="single-news-post" key={i}>
                                            <DataNews {...blog}/>
                                        </div>
                                    );
                                }) : 
                                <div className="d-flex align-items-center justify-content-center text-center">
                                    <Spinner animation="border" variant="primary" role="status"><span className="sr-only">Loading...</span></Spinner>
                                </div>
                            }
                        </div>
                    </Container>
                </section>
            </Fragment>
        );
    }
}

export default Detail;