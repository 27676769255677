import React, { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
// import ReactBnbGallery from 'react-bnb-gallery'

const photoMasterPlan = [
    {
        "photo": '/img/masterplan/new_master_plan.png'
    }
]

const iconMasterPlane = [
    {
        "alt": "backyard",
        "image": "/img/masterplan/backyard.png"
    },
    {
        "alt": "cctv",
        "image": "/img/masterplan/cctv.png"
    },
    {
        "alt": "clean",
        "image": "/img/masterplan/clean.png"
    },
    {
        "alt": "exclusive",
        "image": "/img/masterplan/exclusive.png"
    },
    {
        "alt": "gym",
        "image": "/img/masterplan/gym.png"
    },
    {
        "alt": "jogging",
        "image": "/img/masterplan/jogging.png"
    },
    {
        "alt": "one-gate",
        "image": "/img/masterplan/one-gate.png"
    },
    {
        "alt": "security",
        "image": "/img/masterplan/security.png"
    },
    {
        "alt": "smart-door",
        "image": "/img/masterplan/smart-door.png"
    },
    {
        "alt": "swimming",
        "image": "/img/masterplan/swimming.png"
    }
]

function Detail() {
    return (
        <Fragment>
            <section className="masterplan-area">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            {
                                photoMasterPlan.map((val, i) => {
                                    return(
                                        <img className="img-fluid" src={val.photo} alt="paln" key={i} />
                                    );
                                })
                            }
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <div className="section-heading text-center text-lg-center my-3">
                        <h2 className="title-heading mb-4">PERMATA PURI CIBUBUR TAWARKAN HUNIAN DENGAN KONSEP TROPICAL HOUSE</h2>
                    </div>
                    <Row>
                        <Col lg={12}>
                            <ul className="list-unstyled d-lg-flex d-block">
                                {
                                    iconMasterPlane.map((icon, i) => {
                                        return(
                                            <li key={i}>
                                                <img className="img-fluid mp-feature" src={icon.image} alt={icon.alt} width="390" height="75"/>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
}

export default Detail;