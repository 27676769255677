import React, { Fragment, useState, useEffect } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import axios from 'axios'
import ReactBnbGallery from 'react-bnb-gallery';
import 'react-bnb-gallery/dist/style.css'

function Detail() {
    const [open, setOpen] = useState(false);
    const [listGallery, setListGallery] = useState([])

    useEffect(() => {
        let mount = true;
        const url = process.env.REACT_APP_API + `gallery`;
        axios.get(url)
        .then(res => {
            if(mount){
                const gallery = res.data;
                if(gallery){
                    let dataGallery = [];
                    gallery.forEach((item) => {
                        dataGallery.push({
                            photo: item.link_image,
                            caption: item.name,
                            subcaption: item.name,
                            thumbnail: item.link_image
                        })
                    })
                    setListGallery(dataGallery);
                }
            }
        })
        .catch(error => console.log(error))
        return () => mount = false;
    },[])
    return (
        <Fragment>
            <section className="gallery-area">
                <Container>
                    <Row className="align-items-center justify-content-center">
                        {
                            listGallery.length > 0 ? listGallery.map((photo, i) => {
                                return(
                                    <Col className="p-2" xs={6} lg={4} md={6} sm={6} key={i} data-src={photo.photo} data-sub-html="Gallery">
                                        <div className="gallery-box">
                                            <div onClick={() => setOpen(true)}>
                                                <img className="img-fluid" src={photo.photo} alt={photo.caption} width="269" height="364"/>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            }) : 
                            <div className="d-flex align-items-center justify-content-center text-center">
                                <Spinner animation="border" variant="primary" role="status"><span className="sr-only">Loading...</span></Spinner>
                            </div>
                        }
                    </Row>
                    <ReactBnbGallery
                        show={open}
                        photos={listGallery}
                        onClose={() => setOpen(false)}
                    />
                </Container>
            </section>
        </Fragment>
    );
}

export default Detail;