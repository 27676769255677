import React, { Fragment } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import * as emailjs from 'emailjs-com'
import swal from 'sweetalert'

function Detail() {
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_dwlx28g', 'template_contactus', e.target, 'user_3GIS7mrHY7q7wNhhhCltB')
        .then((result) => {
            swal(result.text, "Email Berhasil Terkirim", "success");
        }, (error) => {
            swal(error.text, "Email Gagal Terkirim", "warning");
        })
        e.target.reset()
    }
    return (
        <Fragment>
            <section className="detail-area-contact">
                <Container>
                    <Row>
                        <Col lg={6}>
                            <div className="section-heading text-center text-lg-left my-3">
                                <h2 className="title-heading">LET'S VISIT</h2>
                            </div>
                            <div className="media contact-info">
                                <span className="contact-info__icon">
                                    <img className="img-fluid" src="/icon/icon-location.png" width="50" height="50" alt="icon location" />
                                </span>
                                <div className="media-body">
                                    <h2 className="mt-2">MARKETING OFFICE</h2>
                                    <p className="">Jl. Kav. No.70, Harjamukti, Kec. Cimanggis, Kota Depok, Jawa Barat 16454</p>
                                </div>
                            </div>
                            <div className="media contact-info">
                                <span className="contact-info__icon">
                                    <img className="img-fluid" src="/icon/icon-email.png" width="50" height="50" alt="icon mail" />
                                </span>
                                <div className="media-body">
                                    <h2 className="mt-2">EMAIL</h2>
                                    <p>marketing@permatapuricibubur.co.id</p>
                                </div>
                            </div>
                            <div className="media contact-info">
                                <span className="contact-info__icon">
                                    <img className="img-fluid" src="/icon/icon-phone.png" width="50" height="50" alt="icon mail" />
                                </span>
                                <div className="media-body">
                                    <h2 className="mt-2">PHONE</h2>
                                    <p>021 - 221 75000</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} className="mt-lg-0 mt-3">
                            <div className="section-heading text-center text-lg-left my-3">
                                <h2 className="title-heading">GET IN TOUCH</h2>
                            </div>
                            <Form className="form-contact contact_form" id="contactForm" noValidate="novalidate" onSubmit={sendEmail}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Control type="text" name="name" placeholder="Name*" />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control type="text" name="phone" placeholder="Phone*" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Control type="email" name="email" placeholder="Email*" />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control type="text" name="subject" placeholder="Subjek*" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Control as="textarea" name="message" rows={5} placeholder="Leave your message here!" />
                                    </Col>
                                    <Button type="submit" className="btn btn-contact mx-auto mx-lg-3">Send</Button>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
}

export default Detail;