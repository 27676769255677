import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

class StratgicLocation extends Component {
    render() {
        return (
            <Fragment>
                <section className="home-strategiclocation-area">
                    <Container>
                        <div className="section-heading">
                            <h2 className="title-heading">Strategic Location</h2>
                        </div>
                        <div className="section-body">
                            <Row>
                                <Col lg={6}>
                                    <Row>
                                        <Col lg={12}>
                                            <img className="img-fluid" src="/img/home/fasilitas_umum.svg" alt="location" />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6}>
                                    <Row>
                                        <div className="section-heading text-center text-lg-left my-3">
                                            <h4 className="title-heading"><strong>FASILITAS</strong> SEKITAR YANG MELENGKAPI KEBUTUHAN ANDA</h4>
                                        </div>
                                        <Col xs={6} lg={4} md={6} sm={12}>
                                            <div className="sl-box">
                                                <div className="sl-image">
                                                    <img className="img-fluid" src="/img/home/Avenzel.jpg" alt="Avenzel" width="295" height="300" />
                                                </div>
                                                <div className="sl-desc">
                                                    <p>Avenzel</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6} lg={4} md={6} sm={12}>
                                            <div className="sl-box">
                                                <div className="sl-image">
                                                    <img className="img-fluid" src="/img/home/Cibubur-Junction.jpg" alt="Cibubur Junction" width="295" height="300" />
                                                </div>
                                                <div className="sl-desc">
                                                    <p>Cibubur Junction</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6} lg={4} md={6} sm={12}>
                                            <div className="sl-box">
                                                <div className="sl-image">
                                                    <img className="img-fluid" src="/img/home/GTO-Cibubur.jpg" alt="GTO Cibubur" width="295" height="300" />
                                                </div>
                                                <div className="sl-desc">
                                                    <p>GTO Cibubur</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6} lg={4} md={6} sm={12}>
                                            <div className="sl-box">
                                                <div className="sl-image">
                                                    <img className="img-fluid" src="/img/home/Hanamasa.jpg" alt="Hanamasa" width="295" height="300" />
                                                </div>
                                                <div className="sl-desc">
                                                    <p>Hanamasa</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6} lg={4} md={6} sm={12}>
                                            <div className="sl-box">
                                                <div className="sl-image">
                                                    <img className="img-fluid" src="/img/home/Jambore-Campsite.jpg" alt="Jambore Campsite" width="295" height="300" />
                                                </div>
                                                <div className="sl-desc">
                                                    <p>Jambore Campsite</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6} lg={4} md={6} sm={12}>
                                            <div className="sl-box">
                                                <div className="sl-image">
                                                    <img className="img-fluid" src="/img/home/Pilar-Indonesia.jpg" alt="Pilar Indonesia" width="295" height="300" />
                                                </div>
                                                <div className="sl-desc">
                                                    <p>Pilar Indonesia</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6} lg={4} md={6} sm={12}>
                                            <div className="sl-box">
                                                <div className="sl-image">
                                                    <img className="img-fluid" src="/img/home/RS-Mitra-Keluarga.jpg" alt="RS Mitra Keluarga" width="295" height="300" />
                                                </div>
                                                <div className="sl-desc">
                                                    <p>RS Mitra Keluarga</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6} lg={4} md={6} sm={12}>
                                            <div className="sl-box">
                                                <div className="sl-image">
                                                    <img className="img-fluid" src="/img/home/RS-Permata.jpg" alt="RS Permata" width="295" height="300" />
                                                </div>
                                                <div className="sl-desc">
                                                    <p>RS Permata</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6} lg={4} md={6} sm={12}>
                                            <div className="sl-box">
                                                <div className="sl-image">
                                                    <img className="img-fluid" src="/img/home/TSM.jpg" alt="TSM" width="295" height="300" />
                                                </div>
                                                <div className="sl-desc">
                                                    <p>Transmart</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            </Fragment>
        );
    }
}

export default StratgicLocation;