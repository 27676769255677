import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import Helmet from 'react-helmet'
import dateFormat from 'dateformat'
import Interweave from 'interweave'
import axios from 'axios'
import { LineShareButton,FacebookShareButton,TwitterShareButton,WhatsappShareButton,LineIcon,FacebookIcon,TwitterIcon,WhatsappIcon } from 'react-share'
import striptags from 'striptags'
// partials
import Whatsapp from '../partials/stiky/WhatsApp'

class NewsDetail extends Component {
    state = {
        content_detail: [],
        more_news: []
    }

    componentDidMount(){
        this.loadDetailNews()
        this.loadListBlog()
    }

    loadDetailNews = () => {
        const slug = this.props.match.params.slug;
        const url = process.env.REACT_APP_API + `article/` + slug;
        axios.get(url)
        .then(res=>{
            const content_detail = res.data;
            this.setState({content_detail})
        })
        .catch((err) => { throw err; });
    }
    
    loadListBlog = () => {
        const url = process.env.REACT_APP_API + `article`
        axios.get(url)
        .then(res => {
            const more_news = res.data;
            this.setState({more_news});
        })
    }
    render() {
        const dtl_news = this.state.content_detail;
        const morenews = this.state.more_news;
        return (
            <Fragment>
                <Helmet>
                    <link rel="canonical" href={"https://permatapuricibubur.co.id/news/" + dtl_news.slug} />
                    <link rel='shortlink' href={'https://permatapuricibubur.co.id/news/' + dtl_news.slug} />
                    {/* Primary Meta Tags */}
                    <title>{dtl_news.title}</title>
                    <meta name="title" content={dtl_news.title}/>
                    <meta name="description" content={striptags(dtl_news.content).slice(0, 170)}/>
                    <meta name="keywords" content={dtl_news.tags}/>

                    <meta itemprop="title" content={dtl_news.title}/>
                    <meta itemprop="description" content={striptags(dtl_news.content).slice(0, 170)}/>
                    <meta itemprop="image" content={dtl_news.link_image}/>

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content={"https://permatapuricibubur.co.id/news/" + dtl_news.slug}/>
                    <meta property="og:title" content={dtl_news.title}/>
                    <meta property="og:description" content={striptags(dtl_news.content).slice(0, 170)}/>
                    <meta property="og:image" content={dtl_news.link_image}/>

                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:url" content={"https://permatapuricibubur.co.id/news/" + dtl_news.slug}/>
                    <meta property="twitter:title" content={dtl_news.title}/>
                    <meta property="twitter:description" content={striptags(dtl_news.content).slice(0, 170)}/>
                    <meta property="twitter:image" content={dtl_news.link_image}/>
                    
                    <meta name="robots" content="index, follow"/>
                    <meta name="googlebot" content="index, follow"/>
                    <meta name="googlebot-news" content="index, follow"/>
                </Helmet>
                <Whatsapp/>
                <Container>
                    <section className="detail-news">
                        <div className="section-body px-lg-5 py-4">
                            <div className="thumb text-center">
                                <img className="img-fluid center" src={dtl_news.link_image} width="611" height="401" alt={dtl_news.title} />
                            </div>
                            <h1 className="title my-3 my-lg-4">{dtl_news.title}</h1>
                            <ul className="list-unstyled meta d-flex align-items-center my-3 my-lg-4">
                                <li>{dateFormat(dtl_news.created_at, "dd/mm/yyyy")}</li>
                            </ul>
                            <div dangerouslySetInnerHTML={{__html: dtl_news.content}} />
                            <div className="share d-flex align-items-center">
                                <p>BAGIKAN :</p>
                                <ul className="list-unstyled d-flex align-items-center">
                                    <li>
                                        <FacebookShareButton title={dtl_news.title} quote={dtl_news.title} hashtag={dtl_news.tags} url={'https://uat.permatapuricibubur.co.id/news/' + dtl_news.slug }>
                                            <FacebookIcon size={40} round={true} />
                                        </FacebookShareButton>
                                    </li>
                                    <li>
                                        <TwitterShareButton title={dtl_news.title} url={'https://uat.permatapuricibubur.co.id/news/' + dtl_news.slug }>
                                            <TwitterIcon size={40} round={true} />
                                        </TwitterShareButton>
                                    </li>
                                    <li>
                                        <WhatsappShareButton title={dtl_news.title} url={'https://uat.permatapuricibubur.co.id/news/' + dtl_news.slug }>
                                            <WhatsappIcon size={40} round={true} />
                                        </WhatsappShareButton>
                                    </li>
                                    <li>
                                        <LineShareButton title={dtl_news.title} url={'https://uat.permatapuricibubur.co.id/news/' + dtl_news.slug }>
                                            <LineIcon size={40} round={true} />
                                        </LineShareButton>
                                    </li>
                                </ul>
                            </div>
                            <div className="more-news">
                                <p></p>
                                <p>Recent News</p>
                            </div>

                            {
                                morenews.length > 0 ? morenews.slice(0, 2).map((blog, i) => {
                                    return(
                                        <div className="single-news-post py-lg-0 py-3 " key={i}>
                                            <Row className="align-items-center">
                                                <Col lg={5} className="post-left pl-lg-0">
                                                    <div className="feature-img position-relative">
                                                        <img className="img-fluid" src={blog.link_image} width="611" height="401" alt={blog.title} />
                                                    </div>
                                                </Col>
                                                <Col lg={7} className="post-right pr-lg-5 mt-lg-0 mt-3">
                                                    <a href={"/news/" + blog.slug}>
                                                        <Interweave tagName="h3" content={blog.title} />
                                                    </a>
                                                    <div dangerouslySetInnerHTML={{__html: blog.content}} />
                                                    <a href={"/news/" + blog.slug} className="btn btn-secondary">Baca</a>
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                }) : 
                                <div className="d-flex align-items-center justify-content-center text-center">
                                    <Spinner animation="border" variant="primary" role="status"><span className="sr-only">Loading...</span></Spinner>
                                </div>
                            }
                        </div>
                    </section>
                </Container>
            </Fragment>
        );
    }
}

export default NewsDetail;