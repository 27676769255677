import React, { Fragment, useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import ReactBnbGallery from 'react-bnb-gallery';
import 'react-bnb-gallery/dist/style.css'

function Gallery() {
    const [open, setOpen] = useState(false);
    const [homeGallery, setHomeGallery] = useState([])

    useEffect(() => {
        let mount = true;
        const url = process.env.REACT_APP_API + `gallery`;
        axios.get(url)
        .then(res => {
            if(mount){
                const gallery = res.data;
                if(gallery){
                    let dataGallery = [];
                    gallery.forEach((item) => {
                        dataGallery.push({
                            photo: item.link_image,
                            caption: item.name,
                            subcaption: item.name,
                            thumbnail: item.link_image
                        })
                    })
                    setHomeGallery(dataGallery);
                }
            }
        })
        .catch(error => console.log(error))
        return () => mount = false;
    },[])

    return (
        <Fragment>
            <section className="home-galery-area">
                <Container>
                    <div className="section-heading">
                        <h2 className="title-heading">Gallery</h2>
                    </div>
                    <div className="section-body">
                        {
                            homeGallery.length && (
                                <Row id="hgallery">
                                    {homeGallery.slice(0, 4).map((home_gallery, i) => {
                                        return(
                                            <Col className="p-2" xs={6} lg={3} md={6} sm={6} key={i} data-src={home_gallery.photo} data-sub-html="Gallery">
                                                <div className="gallery-box">
                                                    <div onClick={() => setOpen(true)}>
                                                        <img className="img-fluid" src={home_gallery.photo} alt={home_gallery.caption} width="269" height="364"/>
                                                    </div>
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            )
                        }
                        <ReactBnbGallery
                            show={open}
                            photos={homeGallery}
                            onClose={() => setOpen(false)}
                        />
                    </div>
                    <div className="more-gallery">
                        <p></p>
                        <a href="/gallery">View More</a>
                    </div>
                </Container>
            </section>
        </Fragment>
    );
}

export default Gallery;