import React, { Fragment, useState } from 'react'
import { Container, Row, Col, Tab, Tabs } from 'react-bootstrap'

function Materials() {
    const [key, setKey] = useState('lantai');
    return (
        <Fragment>
            <section className="home-material-area">
                <Container>
                    <div className="section-heading">
                        <h2 className="title-heading">Materials</h2>
                    </div>
                    <div className="section-body">
                        <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
                            <Tab eventKey="lantai" title="Lantai" className="animate__animated animate__bounceInRight">
                                <Row className="align-items-center justify-content-center">
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/Area_carport_paving.png" className="img-fluid blur-up lazyload bg-img" alt="Area Cartport" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Area Cartport</h3>
                                                        <h4>Paving</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/Area_indoor.png" className="img-fluid blur-up lazyload bg-img" alt="Area Indoor" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Area Indoor</h3>
                                                        <h4>HT 60 x 60 Polished</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/Area_outdoor.png" className="img-fluid blur-up lazyload bg-img" alt="Area Outdoor" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Area Outdoor</h3>
                                                        <h4>HT 60 x 60 Unpolished</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/Area_kamar_mandi.png" className="img-fluid blur-up lazyload bg-img" alt="Area Kamar Mandi" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Area Kamar Mandi</h3>
                                                        <h4>Keramik 30 x 30 ex Roman/Setara</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="dinding" title="Dinding" className="animate__animated animate__bounceInRight">
                                <Row className="align-items-center justify-content-center">
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/Dinding_eksterior.png" className="img-fluid blur-up lazyload bg-img" alt="Dinding Eksterior" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Dinding Eksterior</h3>
                                                        <h4>Plester Aci fin. cat weather shield</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/Dinding_interior.png" className="img-fluid blur-up lazyload bg-img" alt="Dinding Interior" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Dinding Interior</h3>
                                                        <h4>Plester aci fin. cat interior</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/Dinding_Kamar_mandi.png" className="img-fluid blur-up lazyload bg-img" alt="Dinding Kamar Mandi" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Dinding Kamar Mandi</h3>
                                                        <h4>Paving</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/Facade.png" className="img-fluid blur-up lazyload bg-img" alt="Facade" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Facade</h3>
                                                        <h4>Kerawang</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="stuktur" title="Struktur" className="animate__animated animate__bounceInRight">
                                <Row className="align-items-center justify-content-center">
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/Pondasi.png" className="img-fluid blur-up lazyload bg-img" alt="Pondasi" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Pondasi</h3>
                                                        <h4>Foot Plate</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="plafon" title="Plafon" className="animate__animated animate__bounceInRight">
                                <Row className="align-items-center justify-content-center">
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/Plafon_lantai_1.png" className="img-fluid blur-up lazyload bg-img" alt="Plafon Lantai 1" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Plafon Lantai 1</h3>
                                                        <h4>Gypsum fin. abu muda</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/plafon_lantai_2.png" className="img-fluid blur-up lazyload bg-img" alt="Plafon Lantai 2" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Plafon Lantai 2</h3>
                                                        <h4>Gypsum fin. putih</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="pintu_jendela" title="Pintu & Jendela" className="animate__animated animate__bounceInRight">
                                <Row className="align-items-center justify-content-center">
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/Pintu_utama.png" className="img-fluid blur-up lazyload bg-img" alt="Pintu Utama" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Pintu Utama</h3>
                                                        <h4>Solid Engineering Wood Kusen Kayu</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/pintu.png" className="img-fluid blur-up lazyload bg-img" alt="Pintu" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Pintu</h3>
                                                        <h4>Pintu Honeycomb Kusen Kayu</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/jendela.png" className="img-fluid blur-up lazyload bg-img" alt="Jendela" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Jendela</h3>
                                                        <h4>Aluminium fin. coating clear glass 5 mm</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="sanitary" title="Sanitary" className="animate__animated animate__bounceInRight">
                                <Row className="align-items-center justify-content-center">
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/closet_duduk.png" className="img-fluid blur-up lazyload bg-img" alt="Closet Duduk" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Closet Duduk</h3>
                                                        <h4>Toto/setara</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/wastafel.png" className="img-fluid blur-up lazyload bg-img" alt="Wastafel" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Wastafel</h3>
                                                        <h4>Toto/setara</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/shower.png" className="img-fluid blur-up lazyload bg-img" alt="Shower" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Shower</h3>
                                                        <h4>Toto/setara</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="atap" title="Atap" className="animate__animated animate__bounceInRight">
                                <Row className="align-items-center justify-content-center">
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/material_rangka_atap.png" className="img-fluid blur-up lazyload bg-img" alt="Material Rangka Atap" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Material Rangka Atap</h3>
                                                        <h4>Baja Hollow/baja ringan</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/material_penutup_atap.png" className="img-fluid blur-up lazyload bg-img" alt="Material Penutup Atap" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Material Penutup Atap</h3>
                                                        <h4>Bitumen warna abu</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="utility" title="Utility" className="animate__animated animate__bounceInRight">
                                <Row className="align-items-center justify-content-center">
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/listrik.png" className="img-fluid blur-up lazyload bg-img" alt="Listrik" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Listrik</h3>
                                                        <h4>1300 - 3500 watt</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/air_sumur.png" className="img-fluid blur-up lazyload bg-img" alt="Air Sumur" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Air Sumur</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} xs={12}>
                                        <div className="material-box overlay-box">
                                            <div className="material-content">
                                                <div className="image-section">
                                                    <div className="material-img">
                                                        <div className="bg-size blur-up lazyloaded">
                                                            <img src="/img/home/materials/instalasi_ac.png" className="img-fluid blur-up lazyload bg-img" alt="Instalasi AC elektrikal" width="200" height="200"/>
                                                        </div>
                                                    </div>
                                                    <div className="material-detail">
                                                        <h3>Instalasi AC elektrikal</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </div>
                </Container>
            </section>
        </Fragment>
    );
}

export default Materials;