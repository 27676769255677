import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

class Facilites extends Component {
    render() {
        return (
            <Fragment>
                <section className="home-fasilitas-area">
                    <Container>
                        <div className="section-heading">
                            <h2 className="title-heading">Facilities</h2>
                        </div>
                        <div className="section-body">
                            <Row className="align-items-center justify-content-center">
                                <Col xs={6} lg={2} className="mb-5 mb-lg-0">
                                    <div className="item">
                                        <div className="icon-wrap">
                                            <img className="img-fluid" src="/icon/taman-bermain.png" alt="taman bermain" width="60" height="60" />
                                        </div>
                                        <div>
                                            <h3 className="item-title">Taman Bermain</h3>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={2} className="mb-5 mb-lg-0">
                                    <div className="item">
                                        <div className="icon-wrap">
                                            <img className="img-fluid" src="/icon/icon-parkir.png" alt="taman bermain" width="52" height="59"/>
                                        </div>
                                        <div>
                                            <h3 className="item-title">Area Parkir</h3>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={2} className="mb-5 mb-lg-0">
                                    <div className="item">
                                        <div className="icon-wrap">
                                            <img className="img-fluid" src="/icon/kolam-berenang.png" alt="taman bermain" width="55" height="55"/>
                                        </div>
                                        <div>
                                            <h3 className="item-title">Kolam Berenang</h3>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={2} className="mb-5 mb-lg-0">
                                    <div className="item">
                                        <div className="icon-wrap">
                                            <img className="img-fluid" src="/icon/area-bermain.png" alt="taman bermain" width="50" height="50"/>
                                        </div>
                                        <div>
                                            <h3 className="item-title">Area Bermain</h3>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={2} className="mb-5 mb-lg-0">
                                    <div className="item">
                                        <div className="icon-wrap">
                                            <img className="img-fluid" src="/icon/keamanan.png" alt="taman bermain" width="45" height="47"/>
                                        </div>
                                        <div>
                                            <h3 className="item-title">Keamanan 24 jam</h3>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            </Fragment>
        );
    }
}

export default Facilites;