import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'

import './components/styles/classed.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './components/styles/main.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "animate.css";

const tagManagerArgs = {
  gtmId: 'GTM-TWMJVBC'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
serviceWorker.unregister();