import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
// content
import Hero from '../content/masterplan/Hero'
import Detail from '../content/masterplan/Detail'
// partials
import Whatsapp from '../partials/stiky/WhatsApp'

class MasterPlan extends Component {
    render() {
        const desc = "Mulai dari kehadiran akses Tol JORR 2 (exit Jatikarya 2) hingga LRT yang akan menghubungkan kawasan Selatan dan Timur Jakarta hingga ke pusat kota hanya dalam hitungan menit. Lengkapnya jalur konektivitas hingga maraknya kehadiran kawasan komersial."
        return (
            <Fragment>
                <Helmet>
                    <link rel="canonical" href="https://permatapuricibubur.co.id/master-plan" />
                    <link rel='shortlink' href='https://permatapuricibubur.co.id/master-plan' />
                    {/* Primary Meta Tags */}
                    <title>Siteplan - Permata Puri Cibubur</title>
                    <meta name="title" content="Siteplan - Permata Puri Cibubur"/>
                    <meta name="description" content={desc.slice(0, 170)}/>
                    <meta name="keywords" content="Permata puri cibubur pp properti, permata puri cibubur tropical house, proyek pt pp properti, dijual rumah di cibubur, perumahan di cibubur, dekat dengan Pintu Tol menuju Jakarta, Gunung Putri, Bogor, Depok, Cawang dan Bekasi"/>

                    <meta itemprop="title" content="Siteplan - Permata Puri Cibubur"/>
                    <meta itemprop="description" content={desc.slice(0, 170)}/>
                    <meta itemprop="image" content="https://permatapuricibubur.co.id/logo/logo-ppc.svg"/>

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="https://permatapuricibubur.co.id/master-plan"/>
                    <meta property="og:title" content="Siteplan - Permata Puri Cibubur"/>
                    <meta property="og:description" content={desc.slice(0, 170)}/>
                    <meta property="og:image" content="https://permatapuricibubur.co.id/logo/logo-ppc.svg"/>

                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:url" content="https://permatapuricibubur.co.id/master-plan"/>
                    <meta property="twitter:title" content="Siteplan - Permata Puri Cibubur"/>
                    <meta property="twitter:description" content={desc.slice(0, 170)}/>
                    <meta property="twitter:image" content="https://permatapuricibubur.co.id/logo/logo-ppc.svg"/>
                    
                    <meta name="robots" content="index, follow"/>
                    <meta name="googlebot" content="index, follow"/>
                    <meta name="googlebot-news" content="index, follow"/>
                </Helmet>
                <Whatsapp/>
                <Hero/>
                <Detail/>
            </Fragment>
        );
    }
}

export default MasterPlan;