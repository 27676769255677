import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
// contents
import Hero from '../content/home/Hero'
import About from '../content/home/About'
import Facilites from '../content/home/Facilites'
import StragicLovation from '../content/home/StratgicLocation'
import Stories from '../content/home/Stories'
import Strategic from '../content/home/Strategic'
import Gallery from '../content/home/Gallery'
// partials
import Whatsapp from '../partials/stiky/WhatsApp'

class Home extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <link rel="canonical" href="https://permatapuricibubur.co.id/" />
                    <link rel='shortlink' href="https://permatapuricibubur.co.id/" />
                    {/* Primary Meta Tags */}
                    <title>Permata Puri Cibubur</title>
                    <meta name="title" content="Permata Puri Cibubur"/>
                    <meta name="description" content="Permata Puri Cibubur merupakan hunian bernuansa di Cibubur. Persembahan terbaru dari Developer BUMN, PP Properti"/>
                    <meta name="keywords" content="Permata puri cibubur, puri cibubur, pp properti, tropical house, perumahan di cibubur, beli rumah di cibubur"/>

                    <meta itemprop="title" content="Permata Puri Cibubur"/>
                    <meta itemprop="description" content="Permata Puri Cibubur merupakan hunian bernuansa di Cibubur. Persembahan terbaru dari Developer BUMN, PP Properti"/>
                    <meta itemprop="image" content="https://permatapuricibubur.co.id/logo/logo-ppc.svg"/>

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="https://permatapuricibubur.co.id/"/>
                    <meta property="og:title" content="Permata Puri Cibubur"/>
                    <meta property="og:description" content="Permata Puri Cibubur merupakan hunian bernuansa di Cibubur. Persembahan terbaru dari Developer BUMN, PP Properti"/>
                    <meta property="og:image" content="https://permatapuricibubur.co.id/logo/logo-ppc.svg"/>

                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:url" content="https://permatapuricibubur.co.id/"/>
                    <meta property="twitter:title" content="Permata Puri Cibubur"/>
                    <meta property="twitter:description" content="Permata Puri Cibubur merupakan hunian bernuansa di Cibubur. Persembahan terbaru dari Developer BUMN, PP Properti"/>
                    <meta property="twitter:image" content="https://permatapuricibubur.co.id/logo/logo-ppc.svg"/>
                    
                    <meta name="robots" content="index, follow"/>
                    <meta name="googlebot" content="index, follow"/>
                    <meta name="googlebot-news" content="index, follow"/>
                </Helmet>
                <Whatsapp/>
                <Hero/>
                <About/>
                <Facilites/>
                <StragicLovation/>
                <Stories/>
                <Strategic/>
                <Gallery/>
            </Fragment>
        );
    }
}

export default Home;