import React, { Fragment } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import CurrencyFormat from 'react-currency-format'
import * as emailjs from 'emailjs-com'
import swal from 'sweetalert'
import $ from 'jquery'

function Simulasi() {
    function sendPengajuan(e) {
        e.preventDefault();

        emailjs.sendForm('service_dwlx28g', 'template_simulasi', e.target, 'user_3GIS7mrHY7q7wNhhhCltB')
        .then((result) => {
            swal(result.text, "Email Berhasil Terkirim", "success");
        }, (error) => {
            swal(error.text, "Email Gagal Terkirim", "warning");
        })
    }

    const AddCommas = (e) => {
        return "Rp " + new Intl.NumberFormat().format(e);
    }

    $(document).ready(function () {
        const hrgproperti = $('#property-price').val().replace("Rp", "");
        const hrg_prop = hrgproperti.replace(/[^\w\s]/gi, '');
        const harga_properti = parseInt(hrg_prop);
        const persen = parseInt($('#persen').val().replace(/[^\w\s]/gi, ''));
        const uang_muka = harga_properti * (persen / 100);
        const jumlah_pinjaman = (harga_properti - uang_muka).toFixed(0);
        $('#down-payment').val("Rp " + new Intl.NumberFormat().format(uang_muka));
        $('#loan-amount').val("Rp " + new Intl.NumberFormat().format(jumlah_pinjaman));
        const estimasi_bunga = $('#LoanSimulationInterestRate :selected').val();
        const jangka_waktu = $('#LoanSimulationTimePeriod :selected').val();
        const percentage = (estimasi_bunga / 100) / 12;
        const monthly_payment = jumlah_pinjaman * percentage * (1 / (1 - (1 / Math.pow((1 + percentage), (jangka_waktu * 12)))));
        const mp_formatted = AddCommas(Math.round(monthly_payment));
        $('#monthly-payment-result').val("Rp " + mp_formatted);

        $('#LoanSimulationTimePeriod').on('change', function () {
            const dtjangka_waktu = $(this).val();
            const dtestimasi_bunga = $('#LoanSimulationInterestRate :selected').val();
            const dtpercentage = (dtestimasi_bunga / 100) / 12;
            const dtmonthly_payment = jumlah_pinjaman * dtpercentage * (1 / (1 - (1 / Math.pow((1 + dtpercentage), (dtjangka_waktu * 12)))));
            const dtmp_formatted = AddCommas(Math.round(dtmonthly_payment));
            $('#monthly-payment-result').val("Rp " + dtmp_formatted);
        });

        $('#LoanSimulationInterestRate').on('change', function () {
            const dtestimasi_bunga = $(this).val();
            const dtjangka_waktu = $('#LoanSimulationTimePeriod :selected').val();
            const dtpercentage = (dtestimasi_bunga / 100) / 12;
            const dtmonthly_payment = jumlah_pinjaman * dtpercentage * (1 / (1 - (1 / Math.pow((1 + dtpercentage), (dtjangka_waktu * 12)))));
            const dtmp_formatted = AddCommas(Math.round(dtmonthly_payment));
            $('#monthly-payment-result').val("Rp " + dtmp_formatted);
        });
    });
    return (
        <Fragment>
            <section className="simulasi-area">
                <div className="section-heading">
                    <h2 className="title-heading">Simulasi KPR</h2>
                </div>
                <Container>
                    <Form className="form-simulasi-kpr" onSubmit={sendPengajuan}>
                        <Row className="align-items-center">
                            <Col lg="5">
                                <Form.Group>
                                    <Form.Label>Harga Properti</Form.Label>
                                    <CurrencyFormat className="form-control font-weight-bold" thousandSeparator={','} decimalSeparator={'.'} prefix={'Rp '} value="2384552204 " name="property_price" id="property-price" readOnly={true}/>
                                </Form.Group>
                            </Col>
                            <Col lg="2">
                                <Form.Group>
                                    <Form.Label>Jangka Waktu</Form.Label>
                                    <Form.Control as="select" name="time_periode" id="LoanSimulationTimePeriod">
                                        <option value="1">1 Tahun</option>
                                        <option value="2">2 Tahun</option>
                                        <option value="3">3 Tahun</option>
                                        <option value="4">4 Tahun</option>
                                        <option value="5">5 Tahun</option>
                                        <option value="6">6 Tahun</option>
                                        <option value="7">7 Tahun</option>
                                        <option value="8">8 Tahun</option>
                                        <option value="9">9 Tahun</option>
                                        <option value="10">10 Tahun</option>
                                        <option value="11">11 Tahun</option>
                                        <option value="12">12 Tahun</option>
                                        <option value="13">13 Tahun</option>
                                        <option value="14">14 Tahun</option>
                                        <option value="15">15 Tahun</option>
                                        <option value="16">16 Tahun</option>
                                        <option value="17">17 Tahun</option>
                                        <option value="18">18 Tahun</option>
                                        <option value="19">19 Tahun</option>
                                        <option value="20">20 Tahun</option>
                                        <option value="21">21 Tahun</option>
                                        <option value="22">22 Tahun</option>
                                        <option value="23">23 Tahun</option>
                                        <option value="24">24 Tahun</option>
                                        <option value="25">25 Tahun</option>
                                        <option value="26">26 Tahun</option>
                                        <option value="27">27 Tahun</option>
                                        <option value="28">28 Tahun</option>
                                        <option value="29">29 Tahun</option>
                                        <option value="30">30 Tahun</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col lg="5">
                                <Form.Group>
                                    <Form.Label>Jumlah Pinjaman</Form.Label>
                                    <CurrencyFormat className="form-control font-weight-bold" thousandSeparator={'.'} decimalSeparator={','} prefix={'Rp '} name="loan_amount" id="loan-amount" readOnly={true}/>
                                </Form.Group>
                            </Col>
                            <Col lg="5">
                                <Form.Group>
                                    <Form.Label>Uang Muka</Form.Label>
                                    <Row className="no-gutters">
                                        <CurrencyFormat className="form-control font-weight-bold col-9 mr-auto" thousandSeparator={'.'} decimalSeparator={','} prefix={'Rp '} name="down_payment" id="down-payment" value="" readOnly={true}/>
                                        <Form.Control className="col-2" type="text" name="persen" id="persen" value="20%" readOnly={true}/>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg="2">
                                <Form.Group>
                                    <Form.Label>Estimasi Bunga</Form.Label>
                                    <Form.Control as="select" name="interes_rate" id="LoanSimulationInterestRate">
                                        <option value="6.00">6.00%</option>
                                        <option value="6.68">6.68%</option>
                                        <option value="7.70">7.70%</option>
                                        <option value="7.75">7.75%</option>
                                        <option value="8.00">8.00%</option>
                                        <option value="9.25">9.25%</option>
                                        <option value="9.75">9.75%</option>
                                        <option value="9.99">9.99%</option>
                                        <option value="10.29">10.29%</option>
                                        <option value="10.99">10.99%</option>
                                        <option value="11.29">11.29%</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col lg="5">
                                <Form.Group>
                                    <Form.Label>Estimasi Biaya Perbulan</Form.Label>
                                    <CurrencyFormat className="form-control font-weight-bold" thousandSeparator={'.'} decimalSeparator={','} prefix={'Rp '} name="monthly_payment_result" id="monthly-payment-result" readOnly={true}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="send-data" id="datauser">
                            <Row className="mt-3">
                                <Col lg="4">
                                    <Form.Group>
                                        <Form.Label>Nama</Form.Label>
                                        <Form.Control name="nama" placeholder="Input Nama"/>
                                    </Form.Group>
                                </Col>
                                <Col lg="4">
                                    <Form.Group>
                                        <Form.Label>No. Telp</Form.Label>
                                        <Form.Control name="no_telp" placeholder="Input No. Telp"/>
                                    </Form.Group>
                                </Col>
                                <Col lg="4">
                                    <Form.Group>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control name="email" placeholder="Input Email"/>
                                    </Form.Group>
                                </Col>
                                <Button type="submit" className="btn btn-simulasi mx-auto">Dapatkan Penawaran KPR Anda</Button>
                            </Row>
                        </div>
                    </Form>
                </Container>
            </section>
        </Fragment>
    );
}

export default Simulasi;