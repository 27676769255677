import React, { Component, Fragment } from 'react'
import { Container } from 'react-bootstrap'
import Iframe from 'react-iframe'

class Maps extends Component {
    render() {
        return(
            <Fragment>
                <section className="maps-area wow fadeIn mb-0" data-wow-delay="1s">
                    <Container fluid className="p-0">
                        <div className="d-none d-lg-block">
                            <span className="rectangle1" style={{ backgroundImage: 'url(/img/Rectangle.png)' }}></span>
                            <span className="ellipse" style={{ backgroundImage: 'url(/img/Ellipse.png)' }}></span>
                            <span className="rectangle" style={{ backgroundImage: 'url(/img/Rectangle.png)' }}></span>
                        </div>
                        <Iframe className="maps-kantor" tabIndex="0" allowFullScreen={true} aria-hidden="false" style={{border: "0"}} frameBorder="0" width="100%" height="716" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.225400053263!2d106.90542751419493!3d-6.364868764034125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69edb4a91fe77d%3A0x1671d7a00597ad34!2sPermata%20Puri%20Cibubur%20-%20Marketing%20Office!5e0!3m2!1sid!2sid!4v1626874105030!5m2!1sid!2sid"/>
                    </Container>
                </section>
            </Fragment>
        );
    }
}

export default Maps;