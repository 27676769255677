import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
// content
import Detail from '../content/news/Detail'
// partials
import Whatsapp from '../partials/stiky/WhatsApp'

class News extends Component {
    render() {
        const desc= "Permata Puri Cibubur berlokasi di Jalan Trans Yogi, Permata Puri Cibubur merupakan perumahan cluster yang menghadirkan kesan private dan cozy, dimana elemen bangunan yang menyatu dengan alam, pemilihan warna serta material bangunan yang berkesan down to earth.";
        return (
            <Fragment>
                <Helmet>
                    <link rel="canonical" href="https://permatapuricibubur.co.id/news" />
                    <link rel='shortlink' href='https://permatapuricibubur.co.id/news' />
                    {/* Primary Meta Tags */}
                    <title>News - Permata Puri Cibubur</title>
                    <meta name="title" content="News - Permata Puri Cibubur"/>
                    <meta name="description" content={desc.slice(0, 170)}/>
                    <meta name="keywords" content="Permata puri cibubur pp properti, permata puri cibubur tropical house, proyek pt pp properti, dijual rumah di cibubur, perumahan di cibubur, rumah dijual dekat tol, rumah di cibubur dekat mall, rumah di cibubur dekat rumah sakit"/>

                    <meta itemprop="title" content="News - Permata Puri Cibubur"/>
                    <meta itemprop="description" content={desc.slice(0, 170)}/>
                    <meta itemprop="image" content="https://permatapuricibubur.co.id/logo/logo-ppc.svg"/>

                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="https://permatapuricibubur.co.id/news"/>
                    <meta property="og:title" content="News - Permata Puri Cibubur"/>
                    <meta property="og:description" content={desc.slice(0, 170)}/>
                    <meta property="og:image" content="https://permatapuricibubur.co.id/logo/logo-ppc.svg"/>

                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:url" content="https://permatapuricibubur.co.id/news"/>
                    <meta property="twitter:title" content="News - Permata Puri Cibubur"/>
                    <meta property="twitter:description" content={desc.slice(0, 170)}/>
                    <meta property="twitter:image" content="https://permatapuricibubur.co.id/logo/logo-ppc.svg"/>
                    
                    <meta name="robots" content="index, follow"/>
                    <meta name="googlebot" content="index, follow"/>
                    <meta name="googlebot-news" content="index, follow"/>
                </Helmet>
                <Whatsapp/>
                <Detail/>
            </Fragment>
        );
    }
}

export default News;