import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

class Hero extends Component {
    render() {
        return (
            <Fragment>
                <section className="hero-area m-0" style={{backgroundImage: 'url(../img/typeunit/4584/hero.webp)'}}>
                    <div className="overlay">
                        <div className="hero-content wow fadeIn" data-wow-delay=".5s">
                            <Container>
                                <Row className="align-items-center justify-content-center d-flex">
                                    <Col md={8} className="text-center">
                                        <p>
                                            TYPE 45/84
                                        </p>
                                        <h1 className="d-none">Permata Puri Cibubur Type 45/84</h1>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

export default Hero;