import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

class Strategic extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <img className="img-fluid" src="/img/home/map-legend.svg" alt="location" />
                        </Col>
                        <Col lg={6}>
                            <img className="img-fluid" src="/img/home/map2.png" alt="location" />
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Strategic;