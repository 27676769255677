import React, { Component, Fragment } from 'react'
import { Container } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel'

class OtherUnit extends Component {
    render() {
        const options = {
            margin: 20,
            loop: false,
            dots: false,
            nav: false,
            autoplay: true,
            smartSpeed: 1300,
            responsive: {
                0: { items: 1 },
                600: { items: 2 },
                1366: { items: 2 }
            }
        }
        return (
            <Fragment>
                <section className="other-unit-area">
                    <Container>
                        <div className="section-body">
                            <div className="heading-other-unit">
                                <p>Type Unit</p>
                                <p></p>
                            </div>
                            <OwlCarousel className="owl-theme" {...options}>
                                <div className="item">
                                    <img className="img-fluid" src="../img/typeunit/7284/banner.webp" width="611" height="401" alt="unit type 72/84" />
                                    <h3>type 72/84</h3>
                                    <a href="/type-unit/type-72-84">View Detail</a>
                                </div>
                                <div className="item">
                                    <img className="img-fluid" src="../img/typeunit/10590/banner.webp" width="611" height="401" alt="unit type 105/90" />
                                    <h3>type 105/90</h3>
                                    <a href="/type-unit/type-105-90">View Detail</a>
                                </div>
                            </OwlCarousel>
                        </div>
                    </Container>
                </section>
            </Fragment>
        );
    }
}

export default OtherUnit;