import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Interweave from 'interweave'

const dataNews = (props) => <Row className="align-items-center"><Col lg={5} className="post-left ">
<div className="feature-img position-relative">
<a href={"/news/" + props.slug}>
    <img className="img-fluid" src={props.link_image} width="611" height="401" alt={props.title} />
</a>
</div>
</Col>
<Col lg={7} className="post-right pr-lg-5 m-3 m-lg-0">
<a href={"/news/" + props.slug}>
    <Interweave tagName="h2" content={props.title} />
</a>
<div dangerouslySetInnerHTML={{__html: props.content}} />
<a href={"/news/" + props.slug} className="btn btn-secondary">Baca</a>
</Col></Row>

export default dataNews;