import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ReactPlayer from 'react-player'

class Visual extends Component {
    render() {
        return (
            <Fragment>
                <section className="visual-area">
                    <Container>
                        <Row className="text-center">
                            <Col lg={6}>
                                <div className="section-heading mb-5">
                                    <h2 className="title-heading">Lantai 1</h2>
                                </div>
                                <img className="img-fluid" src="../img/typeunit/4584/45_1.png" alt="type unit 45/84 lantai 1" />
                            </Col>
                            <Col lg={6} className="mt-lg-0 mt-3">
                                <div className="section-heading mb-5">
                                    <h2 className="title-heading">Lantai 2</h2>
                                </div>
                                <img className="img-fluid" src="../img/typeunit/4584/45_2.png" alt="type unit 45/84 lantai 2" />
                            </Col>
                            <Col xs={12} lg={6} className="mt-4 mt-lg-5 text-center mx-auto">
                                <div className="video-frame">
                                    <ReactPlayer url="/video/Type4586.mp4" muted={false} playing={false} controls loop={true}/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Fragment>
        );
    }
}

export default Visual;