import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

class Hero extends Component {
    render() {
        return (
            <Fragment>
                <section className="hero-360view-area" style={{backgroundImage: 'url(./img/about/hero.png)'}}>
                    <div className="overlay">
                        <div className="hero-content wow fadeIn" data-wow-delay=".5s">
                            <Container>
                                <Row className="align-items-center justify-content-center d-flex">
                                    <Col md={8} className="text-center">
                                        <p>
                                            360 VIEW
                                        </p>
                                        <h1 className="d-none">Permata Puri Cibubur 360 View</h1>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

export default Hero;